import React, { Component } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import './App.css';

import Section from './components/Section';
import LogoBanner from './components/LogoBanner';
import Config from './constants/Config';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, navigate: null };
  }

  componentDidMount() {
    setTimeout(() => this.setState(s => ({ loaded: true })), 750);
  }

  _navigateToUrl = (url, sectionIndex) => {
    this.setState(s => ({ navigate: sectionIndex }));
    setTimeout(() => window.location.href = url, 2000);
  }

  render() {
    return (
      <div className="App">
        <LogoBanner widthPercent={35} disappear={this.state.navigate !== null }/>
        { this.state.loaded && 
          <CSSTransitionGroup
            transitionName='sections'
            transitionAppear={true}
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}
            component='div'
            className={`Section-wrap${this.state.navigate !== null ? ' Section-wrap-move' : ''}`}
          >
            { Config.sections
                .filter((x, i) => this.state.navigate === null ||
                  this.state.navigate === i)
                .map((x, i) =>
                  <Section
                    {...x}
                    key={i}
                    navigateToUrl={ this._navigateToUrl.bind(null, x.backgroundUrl, i) }
                    animate={ this.state.navigate !== null } />
                )
            }
          </CSSTransitionGroup>
        }
      </div>
    );
  }
}

export default App;
