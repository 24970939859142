export default {
    sections: [
      {
        title: 'Developer',
        titleLocation: 'top-left',
        backgroundUrl: 'https://projects.jordanhayashi.com',
      },
      {
        title: 'Producer',
        titleLocation: 'top-right',
        backgroundUrl: 'https://prod.jordanhayashi.com',
      },
      {
        title: 'Blogger',
        titleLocation: 'bottom-left',
        backgroundUrl: 'https://blog.jordanhayashi.com',
      },
      {
        title: 'Contact Me',
        titleLocation: 'bottom-right',
        backgroundUrl: 'https://prod.jordanhayashi.com/contact',
      },
    ],
};
