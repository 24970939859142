import React from 'react';
import PropTypes from 'prop-types';

import './Section.css';

const TAP_THRESHOLD = 250;

export default class Section extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    backgroundUrl: PropTypes.string.isRequired,
    navigateToUrl: PropTypes.func.isRequired,
    animate: PropTypes.bool.isRequired,
    titleLocation: PropTypes.string,
  };
  static defaultProps = {
    titleLocation: 'top-left',
    animate: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  _onMouseOver = () => {
    this.mouseoutTimeout && clearTimeout(this.mouseoutTimeout);
    this.setState(state => ({ hover: true }));
  }
  _onMouseOut = () => {
    this.mouseoutTimeout = setTimeout(() =>
      this.setState(state => ({ hover: false })), 500);
  }
  _recordTime = () => {
    this.touchStart = Date.now();
    return true;
  }
  _isTap = () => {
    return !this.touchStart || (Date.now() - this.touchStart) < TAP_THRESHOLD;
  }

  _getTitleLocation = () => {
    switch (this.props.titleLocation) {
      case 'top-left':
      case 'tl':
        return 'Section-title-tl';
      case 'top-right':
      case 'tr':
        return 'Section-title-tr';
      case 'bottom-left':
      case 'bl':
        return 'Section-title-bl';
      case 'bottom-right':
      case 'br':
        return 'Section-title-br';
      default:
        return 'Section-title-tl';
    }
  }

  render() {
    const sectionClasses = ['Section'];
    const sectiontitleClasses = ['Section-title', this._getTitleLocation()];
    if (this.state.hover) {
      sectionClasses.push('Section-hover');
      sectiontitleClasses.push('Section-title-hover');
    }
    if (this.props.animate) sectionClasses.push('Section-grow');

    return (
      <div
        className={ sectionClasses.join(' ') }
        onMouseOver={ this._onMouseOver }
        onTouchStart={ () => this._recordTime() && this._onMouseOver() }
        onMouseOut={ this._onMouseOut }
        onTouchEnd={ () => this._isTap() ? this.props.navigateToUrl() : this._onMouseOut() }
        onClick={ this.props.navigateToUrl }
      >
        <iframe
          className={`Section-background${this.state.hover ? ' Section-background-hover' : ''}`}
          scrolling='no'
          src={ this.props.backgroundUrl }
        />
        <h2 className={ sectiontitleClasses.join(' ') }>
          { this.props.title }
        </h2>
      </div>
    );
  }
};
