import React from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import './LogoBanner.css';
import Logo from './Logo';

export default class LogoBanner extends React.Component {
  static propTypes = {
    disappear: PropTypes.bool.isRequired,
    widthPercent: PropTypes.number,
  };
  static defaultProps = {
    disappear: false,
    widthPercent: 50,
  };

  componentWillMount() {
    this._updateDimensions();
  }

  componentDidMount() {
    window.addEventListener('resize', this._updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateDimensions);
  }

  _updateDimensions = () => {
    this.setState(state =>
      ({ width: Math.min(window.innerWidth, window.innerHeight) * this.props.widthPercent / 100}));
  }

  render() {
    return (
      <CSSTransitionGroup
        transitionName='logo'
        transitionAppear={true}
        transitionAppearTimeout={750}
        transitionEnter={false}
        transitionLeave={false}
        component='div'
        className={`Logo-banner-wrap${this.props.disappear ? ' Logo-banner-wrap-disappear' : ''}`}
        style={{
          width: this.state.width,
          height: this.state.width,
        }}
      >
        <div className='Logo-banner'>
          <div className='Logo-banner-content'>
            <Logo />
          </div>
        </div>
      </CSSTransitionGroup>
    );
  }
}
